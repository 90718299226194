import { useQuery } from "@tanstack/react-query";
import { client } from "@/lib/hono";
import { InferResponseType } from "hono";
import { StorageOrderBy, StorageSortBy } from "../types";

export type GetCollectionsResponseType = InferResponseType<
    typeof client.api.storage.query.collections.$get,
    200
>;

export const useGetCollections = (
    page: number,
    resultsPerPage: number,
    search: string,
    sortBy: StorageSortBy,
    orderBy: StorageOrderBy
) => {
    const query = useQuery({
        queryKey: [
            "get-storage-collections",
            { page, resultsPerPage, search, sortBy, orderBy },
        ],
        queryFn: async () => {
            const response = await client.api.storage.query.collections.$get({
                query: {
                    page: page.toString(),
                    resultsPerPage: resultsPerPage.toString(),
                    search,
                    sortBy,
                    orderBy,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch collections");
            }

            const { data, total } = await response.json();
            return { data, total };
        },
    });

    return query;
};
