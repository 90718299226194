import { create } from "zustand";

type StorageModalsAndSheetsState = {
    collectionId: string | null | undefined;
    collectionName: string | null | undefined;
    videoId: string | null | undefined;

    setValues: ({
        collectionId,
        collectionName,
        videoId,
    }: {
        collectionId?: string | null;
        collectionName?: string | null;
        videoId?: string | null;
    }) => void;
    clearValues: () => void;

    collectionSettingsModalOpen: boolean;
    setCollectionSettingsModalOpen: (val: boolean) => void;

    deleteCollectionModalOpen: boolean;
    setDeleteCollectionModalOpen: (val: boolean) => void;
};

export const useStorageModalsAndSheets = create<StorageModalsAndSheetsState>(
    (set) => ({
        videoId: null,
        collectionId: null,
        collectionName: null,

        setValues: ({ collectionId, collectionName, videoId }) => {
            set({
                collectionId,
                collectionName,
                videoId,
            });
        },
        clearValues: () => {
            set({
                videoId: null,
                collectionId: null,
                collectionName: null,
            });
        },

        collectionSettingsModalOpen: false,
        setCollectionSettingsModalOpen: (val) =>
            set({
                collectionSettingsModalOpen: val,
            }),

        deleteCollectionModalOpen: false,
        setDeleteCollectionModalOpen: (val) =>
            set({
                deleteCollectionModalOpen: val,
            }),
    })
);
