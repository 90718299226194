import {
    toast as originalToast,
    ToastOptions as OriginalToastOptions,
    DefaultToastOptions as OriginalDefaultToastOptions,
} from "react-hot-toast";

export interface ExtendedToastOptions extends OriginalToastOptions {
    keyName?: string;
}

export interface ExtendedDefaultToastOptions
    extends OriginalDefaultToastOptions {
    keyName?: string;
}

// Messages for toast.promise
interface PromiseMessages<T> {
    loading: string | JSX.Element;
    success: string | ((result: T) => string | JSX.Element);
    error: string | ((error: any) => string | JSX.Element);
}

// Extended options for toast.promise
interface ExtendedToastPromiseOptions<T> extends ExtendedDefaultToastOptions {
    loading?: ExtendedToastOptions;
    success?: ExtendedToastOptions;
    error?: ExtendedToastOptions;
}

// Custom toast function
function toast(
    message: string | JSX.Element,
    options?: ExtendedToastOptions
): string {
    return originalToast(message, options);
}

toast.success = function (
    message: string | JSX.Element,
    options?: ExtendedToastOptions
): string {
    return originalToast.success(message, options);
};

toast.error = function (
    message: string | JSX.Element,
    options?: ExtendedToastOptions
): string {
    return originalToast.error(message, options);
};

toast.loading = function (
    message: string | JSX.Element,
    options?: ExtendedToastOptions
): string {
    return originalToast.loading(message, options);
};

toast.promise = function <T>(
    promise: Promise<T>,
    messages: PromiseMessages<T>,
    options?: ExtendedToastPromiseOptions<T>
): Promise<T> {
    return originalToast.promise(promise, messages, options);
};

export default toast;
